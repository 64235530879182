import React from "react"
import "../styles/styles.scss"

const Modal = () => {
    return(
        <div className="hero-demo">
            <div className="container">
              <div className='column'>
                <div>
                <div className="main-text"> Request a <span>demo.</span></div>
                <div className="sub-text">  Want to find out more about what our software can do?</div>
                </div>
            </div>
            </div>
            </div>
    )
}

export default Modal