import React from "react"
import "../styles/styles.scss"

import Header from "../components-en/header"
import Footer from "../components-en/footer"
import Modal from "../components-en/modal"

import {Helmet} from 'react-helmet'

const IndexPage = () => (
    <div>
      <Helmet>
        <html lang="en" />
        <title>Skialabs. Request a demo. </title>
        <description>Request a demo of the smart waste platform.</description>
      </Helmet>
      <Header />
      <Modal />
      <Footer />
    </div>
)

export default IndexPage
